/**
 * = Accordions
 */

 .accordion {
  // --bs-accordion-color: var(--bs-body-color);
  // --bs-accordion-bg: var(--bs-body-bg);
  // --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  // --bs-accordion-border-color: var(--bs-border-color);
  // --bs-accordion-border-width: var(--bs-border-width);
  // --bs-accordion-border-radius: var(--bs-border-radius);
  // --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  // --bs-accordion-btn-padding-x: 1.25rem;
  // --bs-accordion-btn-padding-y: 1rem;
  // --bs-accordion-btn-color: var(--bs-body-color);
  // --bs-accordion-btn-bg: var(--bs-accordion-bg);
  // --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  // --bs-accordion-btn-icon-width: 1.25rem;
  // --bs-accordion-btn-icon-transform: rotate(-180deg);
  // --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  // --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  // --bs-accordion-btn-focus-border-color: #86b7fe;
  // --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #0d6efd40;
  // --bs-accordion-body-padding-x: 1.25rem;
  // --bs-accordion-body-padding-y: 1rem;
  // --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  // --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}


.accordion-button {
  text-align: left;

  .btn-check:focus + .btn,
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    background-color: $gray-200;
  }
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-button {
    background-color: transparent;
    &:not(.collapsed) {
      color: $secondary;
    }
    &:focus {
      border-color: $gray-300;
      box-shadow: none;
    }
  }
  .accordion-collapse {
    border: 0;
  }
}
