// ======= sizing ==============

.w-4 {
  width: 16px;
}

.w-5 {
  width: 20px;
}

.w-6 {
  width: 24px;
}

.w-24 {
  width: 96px;
}

.h-4 {
  height: 16px;
}

.h-5 {
  height: 20px;
}

.h-6 {
  height: 24px;
}

.z-100 {
  z-index: 100;
}

.z-200 {
  z-index: 200;
}

// ========== gapping =============

.p-8 {
  padding: 2rem;
}


// write your custom styles here!
.right-col {
  margin-left: 78px;
  width: calc(100% - 78px);
}

.text-current {
  color: currentColor;
}

select.arrow-padding {
  border-right: 8px solid transparent !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/* for headless ui modal component */
#headlessui-portal-root {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
}

.overflow-y-auto {
  overflow-y: auto;
}

.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fixed {
  position: fixed;
}


.bg-translucent {
  background-color: #0000001a;
}

.modal-dialog.fade {
  opacity: .3;
  transform: translateY(-100px);
  transition: all .3s ease-out;
  transition-property: transform, opacity;
}

.modal-dialog.show-up {
  transform: none;
  opacity: 1;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255) !important;
}

.white-color {
  color: white;
}
