@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}
